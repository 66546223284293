import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Button from 'components/inputs/Button';
import Spinner from 'components/Spinner';
import React from 'react';
import styled from 'styled-components';
import { ApiCall, RequestStatus, ApiResponse } from 'swaggerhooks';

type Props<R> = {
  watch: ApiResponse<R> | ApiCall<R, any>;
  ignoreErrorIfHasResponse?: boolean;
  render: (response: R) => React.ReactChild | null;
  fetchingText?: string;
  className?: string;
};

const ApiStatus = <R extends any>({
  watch,
  ignoreErrorIfHasResponse,
  render,
  fetchingText,
  className,
}: Props<R>) => {
  switch (watch.status) {
    case RequestStatus.Idle:
      return null;

    case RequestStatus.Fetching:
      return (
        <Spinner className={className}>{fetchingText ?? 'Laddar...'}</Spinner>
      );

    case RequestStatus.Fetched:
      return <>{render(watch.response!)}</>;

    case RequestStatus.Error:
      if (ignoreErrorIfHasResponse && watch.response !== undefined) {
        return <>{render(watch.response)}</>;
      }
      return (
        <ErrorTryAgain
          onRetryClick={
            'update' in watch ? () => watch.update() : () => watch.run()
          }
        />
      );
  }
};

export default ApiStatus;
