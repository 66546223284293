import React, { FC } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

import Button from '../inputs/Button';
import Color from 'constants/Color';
import Size from 'constants/Size';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.3);
  animation: ${fadeIn} 0.2s;
`;

const slideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(20px);
}
100% {
  opacity: 1;
  transform: translateY(0px);
}
`;
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Color.background.mainBackground};
  animation: ${slideIn} 0.2s;
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const ModalTitleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalTitle = styled.h1`
  flex: 1;
  margin: 20px;
  margin-bottom: 0;
  font-size: ${Size.font.large};
`;

const ModalTitleCloseButton = styled.button`
  padding: 5px;
  margin: 20px;
  margin-bottom: 0;
  border: 0;
  font-size: 25px;
  background: transparent;
  cursor: pointer;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  padding-top: 0;
  gap: 10px;
`;

export const modalTitleClass = 'modal-title';
export const modalTitleCloseButtonClass = 'modal-title-close-button';
export const modalContentClass = 'modal-body';
export const modalButtons = 'modal-buttons';

export interface ModalButton {
  icon?: React.ReactChild;
  label: React.ReactChild;
  onClick?(eve: React.MouseEvent): void;
  disabled?: boolean;
}

interface Props {
  title?: string;
  buttons?: ModalButton[];
  onClose?(eve: React.MouseEvent): void;
  className?: string;
}

const Modal: FC<Props> = ({ title, buttons, onClose, children, className }) => {
  return (
    <Overlay className={className} onClick={onClose}>
      <ModalBody onClick={(eve) => eve.stopPropagation()}>
        <ModalTitleRow>
          {title && (
            <ModalTitle className={modalTitleClass}>{title}</ModalTitle>
          )}
          {onClose && (
            <ModalTitleCloseButton
              className={modalTitleCloseButtonClass}
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ModalTitleCloseButton>
          )}
        </ModalTitleRow>

        <ModalContent className={modalContentClass}>{children}</ModalContent>

        {buttons && buttons.length > 0 && (
          <ModalButtons className={modalButtons}>
            {buttons.map((button, i) => (
              <Button
                disabled={button.disabled}
                icon={button.icon}
                key={i}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}
          </ModalButtons>
        )}
      </ModalBody>
    </Overlay>
  );
};

export default Modal;
