import CustomLegend from 'components/charts/CustomLegend';
import Color from 'constants/Color';
import Size from 'constants/Size';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

export const tickSize = 5;
export const yAxisWidth = 60;

export const screenInsetVarName = '--screen-inset';
export const screenInsetVar = `var(${screenInsetVarName})`;

export const DashboardGridItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background-color: ${Color.background.mainBackground};
`;

export const BaseGraphWrapper = styled.div`
  position: relative;
  height: 25vh;
  min-height: 280px;
  max-height: 350px;
  margin: 10px ${screenInsetVar};
`;

export const GraphWrapper = styled(BaseGraphWrapper)`
  position: relative;
  width: calc(100% - ${yAxisWidth}px);
  margin-left: 0;
  margin-right: ${yAxisWidth}px;
`;

export const MyCustomLegend = styled(CustomLegend)`
  margin-left: ${yAxisWidth}px;
  margin-right: 20px; // space for the 'absolute'-positioned info-button.
`;

const MyUl = styled.ul`
  padding-left: 2em;
  li {
    margin: 10px 0;
  }
`;

export const BulletInfoText: FC<{ bulletText: string }> = ({ bulletText }) => {
  const stringParts = bulletText
    .split('•')
    .filter((part) => part.trim().length > 0);

  if (stringParts.length === 1) return <>{bulletText}</>;

  return (
    <MyUl>
      {stringParts.map((part, i) => (
        <li key={i}>{part}</li>
      ))}
    </MyUl>
  );
};

export const TextButton = styled.button`
  background: none;
  border: none;
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
  color: ${Color.background.accent};
  font: inherit;
  font-size: ${Size.font.small};
  font-weight: normal;
  text-align: left;
`;
