const Size = {
  radius: {
    small: 5,
    medium: 10,
    large: 20,
  },
  padding: {
    page: 20,
  },
  font: {
    fineprint: '0.9rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xLarge: '3rem',
  },
} as const;

export const DeviceMinWidths = {
  tablet: 768,
  desktop: 1200,
} as const;

export const MediaQuery = {
  /** OBS: make phone styling the default and override it with tablet or desktop media queries */
  phone: `@media only screen and (max-width: ${DeviceMinWidths.tablet}px)`,
  tablet: `@media only screen and (min-width: ${DeviceMinWidths.tablet}px)`,
  desktop: `@media only screen and (min-width: ${DeviceMinWidths.desktop}px)`,
} as const;

export default Size;
