import { useCallback, useEffect, useRef, useState } from 'react';

/** Calls 'callback' with an interval of 'intervalMs' milliseconds,
 * and waits for the callback to finish before starting the next run.
 * It is not allowed to startOnMount if the callback function uses parameters.
 **/
const useUpdateInterval = <
  Params extends any[],
  StartOnMount extends Params[number] extends never ? boolean : false
>(
  callback: (...params: Params) => Promise<any> | void,
  intervalMs: number,
  startOnMount?: StartOnMount
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const intervalMsRef = useRef(intervalMs);
  intervalMsRef.current = intervalMs;

  const callbackParamsRef = useRef<Params>([] as any);
  const isRunningRef = useRef(false);
  const [isRunning, setIsRunning] = useState(false);

  const continueInterval = useCallback(() => {
    // If !isRunning, then don't continue.
    if (isRunningRef.current) {
      timeoutRef.current = setTimeout(timeoutCallback, intervalMsRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeoutCallback = useCallback(() => {
    const callbackReturn = callbackRef.current(...callbackParamsRef.current);

    if (callbackReturn instanceof Promise) {
      callbackReturn.then(continueInterval);
    } else {
      continueInterval();
    }
  }, [continueInterval]);

  const startInterval = useCallback(
    (...callbackParams: Params) => {
      callbackParamsRef.current = callbackParams;

      if (timeoutRef.current === null) {
        isRunningRef.current = true;
        setIsRunning(true);
        timeoutRef.current = setTimeout(timeoutCallback, intervalMsRef.current);
      }
    },
    [timeoutCallback]
  );

  const startIntervalImmediately = useCallback(
    (...callbackParams: Params | []) => {
      // if length !== 0, then arguments have been supplied
      if (callbackParams.length !== 0) {
        callbackParamsRef.current = callbackParams as Params;
      }

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      isRunningRef.current = true;
      setIsRunning(true);
      timeoutCallback();
    },
    [timeoutCallback]
  );

  const stopInterval = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    isRunningRef.current = false;
    setIsRunning(false);
  }, []);

  useEffect(() => {
    if (startOnMount) {
      startInterval(...callbackParamsRef.current);
    }

    return () => {
      stopInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    startInterval,
    stopInterval,
    startIntervalImmediately,
    isRunning,
  };
};

export default useUpdateInterval;
