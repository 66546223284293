import React from 'react';
import { FC } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import useAccountInfo from '.';
import Routes from 'constants/Route';
import { fallbackRoute, fallbackRoutePermissions } from 'App';

export const LoginReturnUrlQueryParam = 'from';

interface Props {
  /** if undefined, a user with any or no roles will be allowed. If [1, 2] for example, only users with either role 1 or 2 will be allowed */
  allowedRoleIds?: number[];
}

const RequireAuth: FC<Props> = ({ children, allowedRoleIds }) => {
  const {
    isLoggedIn,
    accountInfo: { user },
  } = useAccountInfo();
  const location = useLocation();

  if (
    !isLoggedIn ||
    (allowedRoleIds?.length &&
      user?.roles?.find((roleId) => allowedRoleIds.includes(roleId))) ===
      undefined
  ) {
    // If user doesn't have permission to view current page, but has permission for fallbackRoute (the one with least permissions), then reroute to fallbackRoute.
    const hasFallbackRoutePermissions =
      user?.roles?.find(
        (roleId) =>
          fallbackRoutePermissions.find((frp) => frp === roleId) !== undefined
      ) !== undefined;

    if (hasFallbackRoutePermissions) return <Redirect to={fallbackRoute} />;

    const searchParams = new URLSearchParams();
    searchParams.set(LoginReturnUrlQueryParam, location.pathname);
    return (
      <Redirect
        to={{
          pathname: Routes.Account.LogIn,
          search: `?${searchParams.toString()}`,
        }}
      />
    );
  }

  return <>{children}</>;
};

export default RequireAuth;
