export function toShortSweNumber(number: number, decimals?: number) {
  let converted = number;
  let suffix = '';
  const decimalMult = Math.pow(10, decimals ?? 0);

  if (number >= 1000000000) {
    converted = number / (1000000000 / decimalMult);
    suffix = 'md';
  } else if (number >= 1000000) {
    converted = number / (1000000 / decimalMult);
    suffix = 'mn';
  } else if (number >= 1000) {
    converted = number / (1000 / decimalMult);
    suffix = 't';
  }

  return `${(Math.round(converted) / decimalMult).toLocaleString(
    'sv-SE'
  )} ${suffix}`;
}
