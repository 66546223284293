const Color = {
  background: {
    accent: '#E30613',
    mainBackground: '#ffffff',
    secondaryBackground: '#d3d3d3',
    darkOverlay: '#00000099',
    focus: '#4795ff',
    everySecondRow: '#00000009',
  },
  chart: {
    a: {
      light: '#FEE71F',
      dark: '#F1DB35',
    },
    b: {
      light: '#E1C113',
      dark: '#CCAE00',
    },
    c: {
      lighter: '#F49BA1',
      light: '#EF6973',
      dark: '#E73941',
      darker: '#A81C27',
    },
    d: {
      lighter: '#81CCCA',
      light: '#15A1BF',
      dark: '#0C6A9E',
    },
  },
  foreground: {
    accent: '#ffffff',
    mainBackground: '#000000',
    secondaryBackground: '#000000',
    focus: '#ffffff',
  },
  boxShadow: {
    main: {
      up: '0px -2px 5px rgba(0,0,0,0.3)',
      down: '0px 2px 5px rgba(0,0,0,0.3)',
      left: '-2px 0px  5px rgba(0,0,0,0.3)',
      right: '2px 0px 5px rgba(0,0,0,0.3)',
    },
    subHeader: {
      down: '0px 1px 5px -1px rgba(0,0,0,0.3)',
      up: '0px -1px 5px -1px rgba(0,0,0,0.3)',
    },
  },
  border: {
    main: '#ddd',
    dark: '#777',
  },
  good: '#00ff00',
  bad: '#ff0000',
} as const;

export default Color;
