import { TooltipProps } from 'recharts/types/component/Tooltip';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';
import Size from 'constants/Size';
import React from 'react';
import Color from 'constants/Color';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  background-color: ${Color.background.mainBackground};
  box-shadow: ${Color.boxShadow.main.down};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: ${Size.font.small};
  margin-bottom: 10px;
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
`;

const Label = styled.span`
  margin: 0 5px;
  font-weight: 700;
`;

const ColorDot = styled.circle`
  stroke: ${Color.border.dark};
`;

const Line = styled.line`
  stroke: ${Color.border.dark};
`;

const Value = styled.span`
  text-align: right;
  margin-left: 5px;
`;
const Unit = styled.span`
  margin-left: 5px;
  font-weight: 600;
`;

type Props = TooltipProps<ValueType, React.ReactText> & {
  unit?: string;
};

const ColorDotTooltip = ({ label, payload, unit }: Props) => {
  return (
    <Wrapper>
      {label && <Title>{String(label)}</Title>}

      <Items>
        {payload?.map((p) => {
          const color = p.color || p.payload.color || p.payload.fill;

          return (
            <React.Fragment key={p.name}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-1 -1 12 12"
                width={13}
                height={13}
              >
                {color === 'line' ? (
                  <Line x1={0} y1={5} x2={10} y2={5} strokeWidth={1} />
                ) : (
                  <ColorDot cx={5} cy={5} r={5} strokeWidth={1} fill={color} />
                )}
              </svg>

              <Label>{p.name}:</Label>
              <Value>
                {typeof p.value === 'number'
                  ? p.value.toLocaleString('sv-SE')
                  : p.value}
              </Value>
              <Unit>{p.unit ?? unit}</Unit>
            </React.Fragment>
          );
        })}
      </Items>
    </Wrapper>
  );
};

export default ColorDotTooltip;
