import { FC, HTMLProps, ReactChild } from 'react';
import styled, { css } from 'styled-components';
import Color from 'constants/Color';
import React from 'react';

interface ButtonStyleProps {
  small?: boolean;
}

export const buttonStyle = css<ButtonStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: ${({ small }) => (small ? '5px 15px' : '10px 20px')};
  min-height: calc(1.5em + 10px);

  border-radius: 0;
  border: none;
  background: ${Color.background.accent};
  color: ${Color.foreground.accent};
  font: inherit;
  font-weight: 600;
  cursor: pointer;

  &:active:not(:disabled) {
    opacity: 0.5;
  }
  &:disabled {
    background: ${Color.background.secondaryBackground};
    cursor: default;
  }
`;

const StyledButton = styled.button<ButtonStyleProps>`
  ${buttonStyle}
`;

const IconWrap = styled.span`
  margin-right: 5px;
`;

interface Props extends HTMLProps<HTMLButtonElement>, ButtonStyleProps {
  icon?: ReactChild;
}

const Button: FC<Props> = ({ icon, children, ...props }) => (
  <StyledButton {...props} as={undefined}>
    {icon && <IconWrap>{icon}</IconWrap>}
    {children}
  </StyledButton>
);

export default Button;
