import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Color from 'constants/Color';

interface Props {
  className?: string;
}

const Spinner: React.SFC<Props> = ({ children, className }) => (
  <Centerer hasChildren={!!children} className={className}>
    <Icon icon={faCircleNotch} className="loading-spinner" />
    {children}
  </Centerer>
);

const Centerer = styled.div<{ hasChildren?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  ${({ hasChildren }) =>
    hasChildren &&
    css`
      & .loading-spinner {
        margin-bottom: 5px;
      }
    `}
`;

const spinAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 40px;
  animation: 1s ${spinAnimation} linear infinite;
  color: inherit;
`;

export const ButtonSpinner = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCircleNotch,
}))`
  animation: 1s ${spinAnimation} linear infinite;
  color: inherit;
`;

export const OverlaySpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: ${Color.background.darkOverlay};
  color: #fff;
`;

export default Spinner;
