import React, { FC, useMemo } from 'react';
import {
  DashboardGridItem,
  GraphWrapper,
  tickSize,
  yAxisWidth,
} from './components';
import DashboardGridItemHeader from './DashboardGridItemHeader';
import styled from 'styled-components';
import Size from 'constants/Size';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ColorDotTooltip from 'components/charts/ColorDotTooltip';
import Color from 'constants/Color';
import { toShortSweNumber } from 'utils/numbers';

const MyDashboardGridItem = styled(DashboardGridItem)`
  position: relative;
  opacity: 0.6;
`;

const ComingSoonOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  font-weight: bold;
  font-size: ${Size.font.medium};
`;

interface Props {}

const PurchasersGridItem: FC<Props> = () => {
  const fakeData = useMemo(
    () =>
      new Array(10)
        .fill(0)
        .map((_, i) => ({
          name: `Betalare ${i + 1}`,
          value: i + Math.random() * 5,
        }))
        .sort((a, b) => (a.value - b.value) * -1),
    []
  );

  return (
    <MyDashboardGridItem>
      <DashboardGridItemHeader
        title="Betalare (topp 10)"
        infoText="Här kommer en lista med dina 10 största kunder och vad de har betalat att visas."
      />

      <GraphWrapper style={{ position: 'relative' }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={fakeData}
            barGap={0}
            maxBarSize={50}
            barCategoryGap={'20%'}
            margin={{ top: 20 }}
          >
            <Tooltip
              animationDuration={200}
              content={<ColorDotTooltip unit="kr" />}
            />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />

            <Bar dataKey={'value'} fill={Color.chart.b.light} />

            <XAxis tickSize={tickSize} dataKey="name" />
            <YAxis
              width={yAxisWidth}
              tickSize={tickSize}
              tickFormatter={(v) =>
                typeof v === 'number' ? toShortSweNumber(v, 1) : v
              }
            />
          </ComposedChart>
        </ResponsiveContainer>

        <ComingSoonOverlay>Kommer snart</ComingSoonOverlay>
      </GraphWrapper>
    </MyDashboardGridItem>
  );
};

export default PurchasersGridItem;
