import Color from 'constants/Color';
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import logoImage from 'images/logo.svg';
import { NavLink } from 'react-router-dom';
import Route from 'constants/Route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import Size, { MediaQuery } from 'constants/Size';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import Modal from 'components/Modal';
import useAccountInfo from 'contexts/useAccountInfo';
import StationSelector from './StationSelector';
import { ReactComponent as XmarkImg } from 'images/xmark.svg';
import { ReactComponent as BarsImg } from 'images/bars.svg';
import RoleIds from 'constants/RoleIds';

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 20px;
  padding-bottom: 10px;
  padding-bottom: 0;

  border-bottom: 2px solid ${Color.border.main};
  background: ${Color.background.mainBackground};
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 15px;
`;

const MenuPane = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100vw;
  z-index: 10;

  width: 100vw;
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-rows: max-content max-content auto;
  gap: 20px 0;
  padding: 20px;
  padding-top: 40px;

  background-color: ${Color.background.accent};
  color: ${Color.foreground.accent};
  transition: left 0.2s;

  ${({ open }) =>
    open &&
    css`
      left: 0;
    `}

  ${MediaQuery.tablet} {
    flex: 1;
    position: unset;

    display: grid;
    grid-template-columns: auto max-content max-content;
    grid-template-rows: auto;
    align-items: flex-end;
    gap: 20px;
    padding: 0px;

    background-color: transparent;
    color: inherit;
  }
`;

const OpenMenuButton = styled.button`
  padding: 10px;
  margin-left: auto;
  margin-right: -10px;
  margin-top: -10px;
  align-self: center;

  border: none;
  background-color: transparent;
  color: inherit;

  svg {
    width: 20px;
    height: 20px;
  }

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const CloseMenuButton = styled.button`
  grid-area: 1 / 2 / 2 / 3;
  padding: 10px;
  margin: -10px;

  border: none;
  background-color: transparent;
  color: inherit;

  svg {
    width: 20px;
    height: 20px;
  }

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const Links = styled.div`
  grid-area: 3 / 1 / 4 / 3;

  display: flex;
  flex-direction: column;
  margin-top: 20px;

  ${MediaQuery.tablet} {
    grid-area: 1 / 1 / 2 / 2;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 40px;
    height: 100%;
    padding-left: 30px;
    padding-right: 10px;
    margin: 0;
    margin-left: 10px;
    margin-bottom: -2px;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${Color.border.main};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${Color.border.dark};
    }
  }
`;

const tabActiveClass = 'active';
const Link = styled(NavLink)`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 20px 0;

    font-size: ${Size.font.small};
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
    border-top: 1px solid ${Color.foreground.accent};
  }

  ${MediaQuery.tablet} {
    &,
    &:active,
    &:hover,
    &:visited {
      min-width: max-content;
      padding: 0;
      padding-bottom: 10px;
      margin: 0;

      border-bottom: 3px solid transparent;
      text-transform: none;

      &.${tabActiveClass} {
        color: ${Color.background.accent};
        border-bottom: 3px solid ${Color.background.accent};
      }
    }

    &:active {
      color: ${Color.background.accent};
    }
  }
`;

const SettingsLink = styled(Link)`
  &:before {
    content: 'Inställningar ';
  }
  border-bottom: 1px solid ${Color.foreground.accent};

  ${MediaQuery.tablet} {
    &:before {
      content: none;
    }

    &,
    &:active,
    &:hover,
    &:visited {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-left: auto;
      font-size: 20px;
      border: none;
      border-bottom: 3px solid transparent;
    }
  }
`;

const MyStationSelector = styled(StationSelector)`
  grid-area: 1 / 1 / 2 / 2;
  margin: auto;
  margin-left: 0;
  padding-right: 10px;
  font-weight: 600;
  overflow: hidden;

  ${MediaQuery.tablet} {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
    margin-bottom: 10px;
  }
`;

const AccountLinks = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${MediaQuery.tablet} {
    grid-area: 1 / 3 / 2 / 4;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    margin-bottom: 11px;
  }
`;

const AccountLink = styled.button`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 0;
    margin: 0;

    font: inherit;
    font-size: ${Size.font.small};
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${Color.background.accent};
  }
`;

const Header: FC = () => {
  const basicData = useBasicData();
  const {
    accountInfo: { user },
    isLoggedIn,
    onLogOut,
  } = useAccountInfo();
  const { push: pushModal, pop: popModal } = useModalStack();

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    function handleKeyPressed(eve: KeyboardEvent) {
      if (eve.ctrlKey && eve.key === 'i') {
        eve.preventDefault();
        const modalId = pushModal(
          <Modal
            title="Version"
            buttons={[{ label: 'Ok', onClick: () => popModal(modalId) }]}
          >
            <b>Setting:</b> {basicData.settings.buildSettings}
            <br />
            <b>Version:</b> {basicData.settings.releaseVersion}
          </Modal>
        );
      }
    }

    window.addEventListener('keydown', handleKeyPressed);

    return () => {
      window.removeEventListener('keydown', handleKeyPressed);
    };
  }, [basicData, pushModal]);

  const mkLink = (to: string, label: string) => (
    <Link
      to={to}
      activeClassName={tabActiveClass}
      onClick={() => setMenuOpen(false)}
    >
      {label}
    </Link>
  );

  const isAdmin = user?.roles?.includes(RoleIds.admin);
  const isStationManager = user?.roles?.includes(RoleIds.stationManager);
  const isBoardMember = user?.roles?.includes(RoleIds.boardMember);

  return (
    <Wrapper>
      <Logo src={logoImage} />

      {isLoggedIn && (
        <OpenMenuButton onClick={() => setMenuOpen(true)}>
          <BarsImg />
        </OpenMenuButton>
      )}

      <MenuPane open={menuOpen}>
        <MyStationSelector onStationSelected={() => setMenuOpen(false)} />

        <CloseMenuButton onClick={() => setMenuOpen(false)}>
          <XmarkImg />
        </CloseMenuButton>

        <AccountLinks>
          <AccountLink>
            <b>{user?.fullName}</b>
          </AccountLink>
          {isLoggedIn && (
            <AccountLink onClick={() => onLogOut()}>Logga ut</AccountLink>
          )}
        </AccountLinks>

        <Links>
          {(isStationManager || isBoardMember || isAdmin) &&
            mkLink(Route.StatsDashboard.Statistics, 'Dashboard')}

          {isAdmin && (
            <>
              {mkLink(Route.AkManager.Statistics, 'Statistik')}
              {mkLink(Route.AkManager.Billing, 'Fakturering')}
              {mkLink(Route.AkManager.QuarterlyReports, 'Kvartalsrapporter')}
              {mkLink(Route.AkManager.AnnualReports, 'Årsrapporter')}
              {mkLink(Route.AkManager.StationCoverage, 'Stationsnät')}
            </>
          )}

          {(isStationManager || isBoardMember || isAdmin) &&
            mkLink(Route.StatsDashboard.AkHero, 'Assist Hero')}

          {isAdmin && (
            <SettingsLink
              to={Route.AkManager.Admin}
              activeClassName={tabActiveClass}
              title="Inställningar"
              onClick={() => setMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faCogs} />
            </SettingsLink>
          )}
        </Links>
      </MenuPane>
    </Wrapper>
  );
};

export default Header;
