import Color from 'constants/Color';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0 10px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const ColorDot = styled.circle`
  stroke: ${Color.border.dark};
`;

const Line = styled.line`
  stroke: ${Color.border.dark};
`;

interface LegendItem {
  color: string;
  dataKey: string;
  payload?: { color?: string };
}

interface Props {
  payload?: LegendItem[];
  className?: string;
}

const CustomLegend: FC<Props> = ({ payload, className, children }) => {
  return (
    <Wrapper className={className}>
      {payload?.map((item, i) => (
        <Item key={i}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-1 -1 12 12"
            width={13}
            height={13}
          >
            {item.payload?.color === 'line' ? (
              <Line x1={0} y1={5} x2={10} y2={5} strokeWidth={1} />
            ) : (
              <ColorDot cx={5} cy={5} r={5} strokeWidth={1} fill={item.color} />
            )}
          </svg>

          {item.dataKey}
        </Item>
      ))}

      {children}
    </Wrapper>
  );
};

export default CustomLegend;
