import { AuthenticationClient, UserClient, WebUser } from 'api';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {
  getLoginToken,
  getRefreshToken,
  storeLoginToken,
  storeRefreshToken,
} from './authLocalStorage';
import {
  getSelectedAkCompanyId,
  storeSelectedAkCompanyId,
} from './userSettingsLocalStorage';
import { useApiCall, useResponse } from 'swaggerhooks';

interface AccountInfo {
  token: string | null;
  refreshToken: string | null;
  /** User info is downloaded async */
  user: WebUser | null;
  selectedStationId: number | null;
}

const accountInfoAtom = atom<AccountInfo>({
  key: 'AccountInfo',
  default: {
    token: getLoginToken(),
    refreshToken: getRefreshToken(),
    user: null,
    selectedStationId: getSelectedAkCompanyId(),
  },
});

export const useAccountInfoInitialization = (isLoggedIn: boolean) => {
  const [, setAccountInfo] = useRecoilState(accountInfoAtom);

  return useResponse(
    UserClient,
    async (c) => {
      if (!isLoggedIn) return null;
      const me = await c.getMe();
      setAccountInfo((ai) => ({ ...ai, user: me }));
    },
    [isLoggedIn]
  );
};

export const useUpdateAccountInfoCall = () => {
  const [, setAccountInfo] = useRecoilState(accountInfoAtom);

  return useApiCall(UserClient, async (c, isLoggedIn: boolean) => {
    if (!isLoggedIn) return null;
    const me = await c.getMe();
    setAccountInfo((ai) => ({ ...ai, user: me }));
  });
};

const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const logoutCall = useApiCall(AuthenticationClient, (c) => c.logoutUser());

  const onLoggedIn = useCallback(
    (loginToken: string, refreshToken: string, user: WebUser) => {
      storeLoginToken(loginToken);
      storeRefreshToken(refreshToken);

      setAccountInfo((ai) => ({
        ...ai,
        token: loginToken,
        refreshToken: refreshToken,
        user: user,
      }));
    },
    [setAccountInfo]
  );

  const onLogOut = useCallback(async () => {
    storeLoginToken(null);
    storeRefreshToken(null);
    await logoutCall.run();
    setAccountInfo((ai) => ({
      ...ai,
      token: null,
      refreshToken: null,
      user: null,
    }));
  }, []);

  const onTokenRefreshed = useCallback(
    (token: string, refreshToken: string) => {
      storeLoginToken(token);
      storeRefreshToken(refreshToken);
      setAccountInfo((ai) => ({ ...ai, refreshToken, token }));
    },
    []
  );

  const onSelectStation = useCallback((stationId: number | null) => {
    storeSelectedAkCompanyId(stationId);
    setAccountInfo((ai) => ({ ...ai, selectedStationId: stationId }));
  }, []);

  return {
    accountInfo,
    isLoggedIn: !!(accountInfo.token && accountInfo.refreshToken),
    onLoggedIn,
    onLogOut,
    onTokenRefreshed,
    onSelectStation,
  };
};

export default useAccountInfo;
