import Color from 'constants/Color';
import Size, { DeviceMinWidths, MediaQuery } from 'constants/Size';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import SummaryRowsMobile from './SummaryRowsMobile';
import { screenInsetVar } from '../../components/components';

export const RowsWrapper = styled.div`
  display: grid;
  overflow: auto;

  ${MediaQuery.tablet} {
    margin: 0 ${screenInsetVar};
  }
`;

export const RowBackground = styled.div`
  grid-column: 1/-1;
  background-color: ${Color.background.everySecondRow};
`;

export const RowTitle = styled.div`
  grid-column: 1/2;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const ColumnHead = styled.div`
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
  margin-right: 10px;

  font-size: ${Size.font.small};
  font-weight: 600;
`;

export const ValueCell = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  min-width: max-content;
  margin: auto 10px;

  text-align: center;
  font-weight: normal;
`;

export interface SummaryRow {
  title: string;
  money?: number;
  towings: number;
  heavyTowings: number;
  invoicedTowings?: number;
}

interface Props {
  rows: SummaryRow[];
  noMoneyData?: boolean;
}

const SummaryRowsDesktop: FC<Props> = ({ noMoneyData, rows }) => {
  const [showMobileView, setShowMobileView] = useState(
    window.innerWidth < DeviceMinWidths.tablet
  );

  useEffect(() => {
    const resizeListener = () => {
      if (!showMobileView && window.innerWidth < DeviceMinWidths.tablet) {
        setShowMobileView(true);
      }
      if (showMobileView && window.innerWidth >= DeviceMinWidths.tablet) {
        setShowMobileView(false);
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [showMobileView]);

  if (showMobileView) return <SummaryRowsMobile rows={rows} />;

  return (
    <RowsWrapper
      style={{
        gridTemplateColumns: noMoneyData
          ? '1fr 0 0 minmax(100px, auto) minmax(100px, auto)'
          : '1fr minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto)',
      }}
    >
      {!noMoneyData && (
        <>
          <ColumnHead style={{ gridColumn: '2/3' }}>Intäkter</ColumnHead>
          <ColumnHead style={{ gridColumn: '3/4' }}>Fakturerade</ColumnHead>
        </>
      )}
      <ColumnHead style={{ gridColumn: '4/5' }}>Antal</ColumnHead>
      <ColumnHead style={{ gridColumn: '5/6' }}>Tungvikt</ColumnHead>

      {rows.map(
        ({ title, money, towings, heavyTowings, invoicedTowings }, i) => {
          const gridRowStart = i * 2 + 2;
          const gridRowEnd = gridRowStart + 2;

          return (
            <React.Fragment key={title}>
              {i % 2 === 0 && (
                <RowBackground
                  style={{
                    gridRowStart: gridRowStart,
                    gridRowEnd,
                  }}
                  key={title}
                />
              )}

              <RowTitle
                style={{
                  gridRowStart: gridRowStart,
                  gridRowEnd: gridRowEnd,
                }}
              >
                {title}
              </RowTitle>

              {money !== undefined && (
                <ValueCell
                  style={{ gridRowStart, gridRowEnd, gridColumn: '2/3' }}
                >
                  {money.toLocaleString('sv-SE')} kr
                </ValueCell>
              )}

              {invoicedTowings !== undefined && (
                <ValueCell
                  style={{ gridRowStart, gridRowEnd, gridColumn: '3/4' }}
                >
                  {invoicedTowings.toLocaleString('sv-SE')} (
                  {Math.floor(
                    (invoicedTowings / (towings || 0.0001)) * 100
                  ).toLocaleString('sv-SE')}
                  %)
                </ValueCell>
              )}

              <ValueCell
                style={{ gridRowStart, gridRowEnd, gridColumn: '4/5' }}
              >
                {towings.toLocaleString('sv-SE')}
              </ValueCell>

              <ValueCell
                style={{
                  gridRowStart,
                  gridRowEnd,
                  gridColumn: '5/6',
                }}
              >
                {heavyTowings.toLocaleString('sv-SE')}{' '}
              </ValueCell>
            </React.Fragment>
          );
        }
      )}
    </RowsWrapper>
  );
};

export default SummaryRowsDesktop;
