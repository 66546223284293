import Size from 'constants/Size';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import InfoButton from './InfoButton';
import { screenInsetVar } from './components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 20px ${screenInsetVar};
  padding-bottom: 0;
`;

const DashboardGridItemHeaderTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${Size.font.medium};
  font-weight: bold;
  overflow: hidden;
`;

interface Props {
  title: ReactNode;
  infoText?: ReactNode;
}

const DashboardGridItemHeader: FC<Props> = ({ title, infoText }) => {
  return (
    <Wrapper>
      <DashboardGridItemHeaderTitle>{title}</DashboardGridItemHeaderTitle>
      {infoText !== undefined && infoText !== null && (
        <InfoButton>{infoText}</InfoButton>
      )}
    </Wrapper>
  );
};

export default DashboardGridItemHeader;
