import React, { FC, useMemo } from 'react';
import {
  BaseGraphWrapper,
  BulletInfoText,
  DashboardGridItem,
} from '../../components/components';
import DashboardGridItemHeader from '../../components/DashboardGridItemHeader';
import { DashboardInboundStats } from 'api';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import ColorDotTooltip from 'components/charts/ColorDotTooltip';
import styled, { keyframes } from 'styled-components';
import Size from 'constants/Size';
import InboundList from './InboundList';
import chartColors from './chartColors';

const chartHeightAdjustment = -17;

const PiechartWrapper = styled(BaseGraphWrapper)`
  height: calc(25vh + ${chartHeightAdjustment}px);
  min-height: calc(280px + ${chartHeightAdjustment}px);
  max-height: calc(350px + ${chartHeightAdjustment}px);
`;

const animationDurationSeconds = 1;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const TotalSum = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${Size.font.small};
  text-align: center;

  animation: ${fadeInAnimation} ${animationDurationSeconds}s;

  b {
    font-weight: 600;
  }
`;

interface Props {
  inboundStats: DashboardInboundStats;
}

const InboundGridItem: FC<Props> = ({ inboundStats }) => {
  const sortedRows = useMemo(
    () =>
      [...inboundStats.rows]
        .sort((a, b) => b.revenue - a.revenue)
        .map((row, i) => ({
          ...row,
          color: chartColors[i % chartColors.length],
        })),
    [inboundStats.rows]
  );

  return (
    <DashboardGridItem>
      <DashboardGridItemHeader
        title="Ingångar"
        infoText={<BulletInfoText bulletText={inboundStats.infoText} />}
      />

      <PiechartWrapper>
        <TotalSum>
          <b>Totalbelopp:</b>
          <span>{inboundStats.totalRevenue} kr</span>
        </TotalSum>

        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={sortedRows}
              dataKey="revenue"
              nameKey="inboundName"
              innerRadius="45%"
              animationBegin={0}
              animationDuration={animationDurationSeconds * 1000}
            >
              {sortedRows.map((row, i) => (
                <Cell key={i} fill={row.color} />
              ))}
            </Pie>

            <Tooltip content={<ColorDotTooltip label="Ingång" unit="kr" />} />
          </PieChart>
        </ResponsiveContainer>
      </PiechartWrapper>

      <InboundList rows={sortedRows} />
    </DashboardGridItem>
  );
};

export default InboundGridItem;
