import { IdType } from 'api';
import { useCallback } from 'react';
import useBasicData from './useBasicData';

const useGetFullStationName = () => {
  const { stationCityNameByStationID, idInfoByIdType } = useBasicData();

  return useCallback(
    (stationId: number | null): string => {
      if (stationId === null) return 'Assistancekåren';

      const stations = idInfoByIdType[IdType.StationID];
      return stations && stations[stationId]
        ? `${stations[stationId]?.name} ${stationCityNameByStationID[stationId]}`
        : 'Okänd station';
    },
    [idInfoByIdType, stationCityNameByStationID]
  );
};

export default useGetFullStationName;
