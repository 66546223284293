import { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import Size, { MediaQuery } from 'constants/Size';

import { useResponse } from 'swaggerhooks';
import { DashboardClient } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import useGetFullStationName from 'contexts/useGetFullStationName';
import ApiStatus from 'api/ApiStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import assistancekarenLogo from 'images/assistancekaren-round-logo.svg';
import DashboardGrid from './DashboardGrid';
import { screenInsetVar, screenInsetVarName } from './components/components';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 20px;
  overflow: auto;
  padding-bottom: 20px;
  ${screenInsetVarName}: ${Size.padding.page / 2}px;

  ${MediaQuery.tablet} {
    padding-top: 40px;
    background-color: #f7f7f7;
    ${screenInsetVarName}: ${Size.padding.page}px;
  }
`;

const PageTitle = styled.h1`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  margin: 0;
  padding: 0 ${screenInsetVar};

  word-break: break-all;
  font-size: ${Size.font.large};
  font-weight: bold;

  svg {
    font-size: 0.7em;
  }

  ${MediaQuery.tablet} {
    align-self: center;
    font-size: ${Size.font.xLarge};
  }
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  background-image: url(${assistancekarenLogo});
  background-size: 1.1em;
`;

const DashboardStatisticsPage: FC = () => {
  const {
    accountInfo: { selectedStationId },
  } = useAccountInfo();
  const getFullStationName = useGetFullStationName();

  const statsResponse = useResponse(
    DashboardClient,
    async (c) => {
      const [stats, towingCount] = await Promise.all([
        c.getDashboardStats(selectedStationId),
        c.getTodaysCompletedTowingCount(selectedStationId),
      ]);
      return { stats, towingCount, fetchedStationId: selectedStationId };
    },
    [selectedStationId]
  );

  return (
    <Wrapper>
      <PageTitle>
        {selectedStationId !== null ? (
          <FontAwesomeIcon icon={faWarehouse} />
        ) : (
          <Logo />
        )}
        {getFullStationName(selectedStationId)}
      </PageTitle>
      <ApiStatus
        watch={statsResponse}
        render={(response) => (
          <MainContent>
            <DashboardGrid
              stats={response.stats}
              todaysTowingCount={response.towingCount}
            />
          </MainContent>
        )}
      />
    </Wrapper>
  );
};

export default DashboardStatisticsPage;
