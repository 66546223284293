import Color from 'constants/Color';

const chartColors = [
  Color.chart.c.darker,
  Color.chart.c.dark,
  Color.chart.c.light,
  Color.chart.c.lighter,
  Color.chart.d.lighter,
  Color.chart.d.light,
  Color.chart.d.dark,
];

export default chartColors;
