import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const List = styled.ol``;

const BankIdQrInstructions: FC = () => {
  return (
    <Wrapper>
      <b>Gör så här:</b>

      <List>
        <li>Öppna BankID-appen på din andra enhet.</li>
        <li>Klicka på "Skanna QR-kod"-knappen.</li>
        <li>
          Tillåt att BankID-appen får använda din kamera och rikta kameran mot
          QR-koden ovanför.
        </li>
        <li>Signera med BankID.</li>
      </List>
    </Wrapper>
  );
};

export default BankIdQrInstructions;
