import React, { FC } from 'react';
import { DashboardStats } from 'api';
import InboundGridItem from './InboundGridItem';
import PurchasersGridItem from '../components/PurcharsersGridItem';
import AKHeroGridItem from './AKHeroGridItem';
import styled from 'styled-components';
import Size, { MediaQuery } from 'constants/Size';
import JobsGridItem from './JobsGridItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Size.padding.page}px;
  padding: 0;

  ${MediaQuery.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${Size.padding.page}px;
    margin: 0 auto;
    padding: ${Size.padding.page}px;
    width: 100%;
    max-width: 1500px;
  }
`;

interface Props {
  stats: DashboardStats;
  todaysTowingCount: number;
}

const DashboardGrid: FC<Props> = ({ stats, todaysTowingCount }) => {
  return (
    <Wrapper>
      <JobsGridItem stats={stats} todaysTowingCount={todaysTowingCount} />
      <InboundGridItem inboundStats={stats.inboundStats} />
      <PurchasersGridItem />
      <AKHeroGridItem />
    </Wrapper>
  );
};

export default DashboardGrid;
