import Color from 'constants/Color';
import Size from 'constants/Size';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { screenInsetVar } from '../../components/components';

const RowsWrapper = styled.div`
  display: grid;
  overflow: visible;
  max-width: 100%;
`;

const RowBackground = styled.div`
  grid-column: 1/-1;
  background-color: ${Color.background.everySecondRow};
`;

const Title = styled.div`
  grid-column: 1/-1;
  margin: 10px ${screenInsetVar};
  margin-bottom: 0;
  font-size: ${Size.font.medium};
  font-weight: bold;
`;

const Summary = styled.div<{ row: number; column: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 10px;
  margin: 5px ${screenInsetVar};
  margin-right: 0;

  ${({ row, column }) => css`
    ${column > 1
      ? css`
          margin-bottom: 10px;
          grid-column: ${column - 1} / ${column};
          grid-row: ${row * 3 + 3} / ${row * 3 + 4};
        `
      : css`
          grid-column: ${column + 1} / ${column + 2};
          grid-row: ${row * 3 + 2} / ${row * 3 + 3};
        `}
  `}
`;

const SummaryValue = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  font-weight: 600;
`;

const SummarySub = styled.span`
  font-size: ${Size.font.fineprint};
  font-weight: 500;
`;

const Small = styled.div`
  font-weight: normal;
`;

export interface SummaryRow {
  title: string;
  money?: number;
  towings: number;
  heavyTowings: number;
  invoicedTowings?: number;
}

interface Props {
  rows: SummaryRow[];
}

const SummaryRowsMobile: FC<Props> = ({ rows }) => {
  return (
    <RowsWrapper
      style={{
        gridTemplateColumns: 'auto auto',
      }}
    >
      {rows.map(
        ({ title, money, towings, heavyTowings, invoicedTowings }, i) => {
          const gridRowStart = i * 3 + 1;
          const gridRowEnd = gridRowStart + 3;

          return (
            <React.Fragment key={title}>
              {i % 2 === 0 && (
                <RowBackground
                  style={{
                    gridRowStart: gridRowStart,
                    gridRowEnd,
                  }}
                  key={title}
                />
              )}

              <Title
                style={{
                  gridRowStart: gridRowStart,
                  gridRowEnd: gridRowStart + 1,
                }}
              >
                {title}
              </Title>

              {money !== undefined && (
                <Summary title="Intäkter" column={0} row={i}>
                  <SummarySub>Intäkter</SummarySub>
                  <SummaryValue>
                    {money.toLocaleString('sv-SE')} kr
                  </SummaryValue>
                </Summary>
              )}

              {invoicedTowings !== undefined && (
                <Summary
                  title="Antal fakturerade bärgningar"
                  column={1}
                  row={i}
                >
                  <SummarySub>Fakturerade</SummarySub>
                  <SummaryValue>
                    {invoicedTowings.toLocaleString('sv-SE')}{' '}
                    <Small>
                      (
                      {Math.floor(
                        (invoicedTowings / (towings || 0.00001)) * 100
                      ).toLocaleString('sv-SE')}
                      %)
                    </Small>
                  </SummaryValue>
                </Summary>
              )}

              <Summary title="Antal bärgningar" column={2} row={i}>
                <SummarySub>Antal</SummarySub>
                <SummaryValue>{towings.toLocaleString('sv-SE')}</SummaryValue>
              </Summary>

              <Summary title="Tungvikts-bärgningar" column={3} row={i}>
                <SummarySub>Tungvikt</SummarySub>
                <SummaryValue>
                  {heavyTowings.toLocaleString('sv-SE')}
                </SummaryValue>
              </Summary>
            </React.Fragment>
          );
        }
      )}
    </RowsWrapper>
  );
};

export default SummaryRowsMobile;
