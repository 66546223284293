export const RouteParam = {} as const;

const Route = {
  Account: {
    LogIn: '/konto/logga-in',
  },
  AkManager: {
    Statistics: '/assistancekaren/statistik',
    Billing: '/assistancekaren/fakturering',
    QuarterlyReports: '/assistancekaren/rapporter/kvartal',
    AnnualReports: '/assistancekaren/rapporter/arlig',
    StationCoverage: '/assistancekaren/stations-tackning',
    Admin: '/assistancekaren/admin',
  },

  StatsDashboard: {
    Statistics: '/dashboard/statistik',
    AkHero: '/dashboard/ak-hero',
  },
} as const;

export default Route;
