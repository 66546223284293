import Color from 'constants/Color';
import React from 'react';
import { FC, ReactChild } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button<{ active?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 10px 20px;

  font: inherit;
  font-weight: normal;
  border: 1px solid ${Color.border.main};
  background: ${Color.background.mainBackground};
  color: ${Color.foreground.mainBackground};
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          background-color: ${Color.background.accent};
          color: ${Color.foreground.accent};
          font-weight: bold;
          border: 1px solid ${Color.background.accent};
        `
      : ''}
`;

interface Props {
  buttonLabels: ReactChild[];
  onClick(index: number): void;
  selectedButton: number;
  className?: string;
}

const ButtonStrip: FC<Props> = ({
  buttonLabels,
  onClick,
  selectedButton,
  className,
}) => (
  <Wrapper className={className}>
    {buttonLabels.map((label, i) => (
      <Button key={i} onClick={() => onClick(i)} active={selectedButton === i}>
        {label}
      </Button>
    ))}
  </Wrapper>
);

export default ButtonStrip;
