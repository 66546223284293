import React from 'react';
import styled from 'styled-components';
import { defaultInputStyle } from './Input';
import selectArrow from 'images/selectArrow.svg';

const StyledSelect = styled.select`
  ${defaultInputStyle}

  padding-right: calc(30px + 0.5em);
  appearance: none;
  background-image: url('${selectArrow}');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: right;
`;

export interface SelectOption<V> {
  value: V;
  label: string;
  disabled?: boolean;
}

interface Props<V> {
  options: SelectOption<V>[];

  value: V;
  onChange(value: V): void;
  disabled?: boolean;
  className?: string;
}

const Select = <V extends any>({
  options,
  value,
  onChange,
  disabled,
  className,
}: Props<V>) => {
  const handleChange = (eve: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(options[Number(eve.target.value)].value);
  };

  const valueIndex = options.findIndex((opt) => opt.value === value);

  return (
    <StyledSelect
      onChange={handleChange}
      value={valueIndex}
      disabled={disabled}
      className={className}
    >
      {options.map((option, index) => (
        <option value={index} disabled={option.disabled} key={index}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;
