import { useCallback, useMemo, useEffect } from 'react';
import { BasicDataModel, BasicDataClient } from 'api';
import { useApiCall, useResponse } from 'swaggerhooks';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';

const basicDataAtom = atom({
  key: 'basicData',
  default: new BasicDataModel(),
});

export const useUpdateBasicData = () => {
  const setBasicData = useSetRecoilState(basicDataAtom);

  const { run: getBasicData, status } = useApiCall(BasicDataClient, (c) =>
    c.getBasicData()
  );

  const update = useCallback(
    async (basicData?: BasicDataModel) => {
      if (basicData) {
        setBasicData(basicData);
        return;
      }

      const [gotBasicData, error] = await getBasicData();

      if (gotBasicData && !error) {
        setBasicData(gotBasicData);
      }
    },
    [getBasicData, setBasicData]
  );

  return useMemo(
    () => ({
      update,
      status,
    }),
    [status, update]
  );
};

export const useInitializeBasicData = (isLoggedIn: boolean) => {
  const setBasicData = useSetRecoilState(basicDataAtom);
  return useResponse(
    BasicDataClient,
    async (c) => {
      if (!isLoggedIn) return;
      const basicData = await c.getBasicData();
      setBasicData(basicData);
    },
    [isLoggedIn]
  );
};

const useBasicData = () => {
  return useRecoilValue(basicDataAtom);
};

export default useBasicData;
