import { FC, useMemo } from 'react';
import React from 'react';
import SummaryRowsDesktop, { SummaryRow } from './SummaryRowsDesktop';

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
} from 'recharts';
import Color from 'constants/Color';
import ColorDotTooltip from 'components/charts/ColorDotTooltip';
import LineScatter from '../../components/LineScatter';
import { toShortSweNumber } from 'utils/numbers';
import {
  GraphWrapper,
  MyCustomLegend,
  BulletInfoText,
  DashboardGridItem,
  tickSize,
  yAxisWidth,
} from '../../components/components';
import { DashboardStats } from 'api';
import DashboardGridItemHeader from '../../components/DashboardGridItemHeader';

interface Props {
  stats: DashboardStats;
  todaysTowingCount: number;
}

const WeeklyJobs: FC<Props> = ({ stats, todaysTowingCount }) => {
  const [weeklyStats, thisYearWeekLabel, prevYearWeekLabel] = useMemo(() => {
    const thisYearLabel = Array.from(
      new Set(stats.weeklyStats.map((ms) => ms.week.yearByISOWeekNumbering))
    ).join('/');
    const prevYearLabel = Array.from(
      new Set(stats.weeklyStats.map((ms) => ms.week.yearByISOWeekNumbering - 1))
    ).join('/');

    const weeklyStats = stats.weeklyStats
      .sort((a, b) => a.week.weekNumber - b.week.weekNumber)
      .map((ms, i, array) => ({
        weekSortNr: ms.week.yearByISOWeekNumbering * 100 + ms.week.weekNumber,
        week: `Vecka ${ms.week.weekNumber}`,
        bestYear: ms.completedTowingCount.yearOfTopValue,
        'Bäst år': ms.completedTowingCount.topValue,
        [thisYearLabel]: ms.completedTowingCount.value,
        [prevYearLabel]: ms.completedTowingCount.previousYearValue,
        heavyTowings: ms.heavyVehiclesCount.value,
        Idag:
          i === array.length - 1 ? todaysTowingCount ?? undefined : undefined,
      }));

    return [weeklyStats, thisYearLabel, prevYearLabel] as const;
  }, [stats, todaysTowingCount]);

  return (
    <>
      <GraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={weeklyStats}
            barGap={0}
            maxBarSize={50}
            barCategoryGap={'20%'}
            margin={{ top: 20 }}
          >
            <Tooltip
              animationDuration={200}
              content={<ColorDotTooltip unit="Bärgningar" />}
            />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />

            <Bar dataKey={prevYearWeekLabel} fill={Color.chart.c.light} />
            <Bar
              dataKey={thisYearWeekLabel}
              fill={Color.chart.c.darker}
              stackId="thisWeekStack"
            />
            <Bar
              dataKey="Idag"
              fill={Color.chart.d.dark}
              stackId="thisWeekStack"
            />
            <Scatter
              dataKey="Bäst år"
              shape={LineScatter}
              color="line"
              fill="line"
            />

            <XAxis tickSize={tickSize} dataKey="week" />
            <YAxis
              width={yAxisWidth}
              tickSize={tickSize}
              tickFormatter={(v) =>
                typeof v === 'number' ? toShortSweNumber(v, 1) : v
              }
            />
            <Legend content={<MyCustomLegend />} />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphWrapper>

      {weeklyStats ? (
        <SummaryRowsDesktop
          noMoneyData
          rows={[...weeklyStats]
            .sort((a, b) => b.weekSortNr - a.weekSortNr)
            .map(
              (wi): SummaryRow => ({
                title: wi.week,
                towings: Number(wi[thisYearWeekLabel]),
                heavyTowings: wi.heavyTowings,
              })
            )}
        />
      ) : null}
    </>
  );
};

export default WeeklyJobs;
