import { FC, useState, useEffect } from 'react';
import { useInitializeBasicData } from 'contexts/useBasicData';
import { RequestStatus } from 'swaggerhooks';
import Spinner from 'components/Spinner';
import React from 'react';
import styled from 'styled-components';
import akLogo from 'images/logo.svg';
import useAccountInfo, {
  useAccountInfoInitialization,
} from 'contexts/useAccountInfo';
import { useUsersInitialization } from 'contexts/useUsers';
import RoleIds from 'constants/RoleIds';

const StatusPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AppName = styled.div`
  margin-bottom: 20px;

  img {
    width: 400px;
    max-width: 90vw;
  }
`;

const MySpinner = styled(Spinner)`
  flex: none;
`;

const AppInitialization: FC = ({ children }) => {
  const {
    accountInfo: { user },
    isLoggedIn,
  } = useAccountInfo();
  const [delayedIsLoggedIn, setDelayedIsLoggedIn] = useState(isLoggedIn);

  // delay isLoggedIn state so that ApiCallConfiguration has time to update its tokens,
  // before all of the hooks try to use the tokens to fetch data.
  useEffect(() => {
    setTimeout(() => {
      setDelayedIsLoggedIn(isLoggedIn);
    }, 0);
  }, [isLoggedIn]);

  const { status: basicDataStatus } = useInitializeBasicData(delayedIsLoggedIn);
  const { status: accountInfoStatus } = useAccountInfoInitialization(
    delayedIsLoggedIn
  );
  useUsersInitialization(
    delayedIsLoggedIn && !!user?.roles?.includes(RoleIds.admin)
  );

  // Required before the rest of the app can be rendered
  const requiredResourcesStatuses = [basicDataStatus, accountInfoStatus];
  if (requiredResourcesStatuses.includes(RequestStatus.Fetching)) {
    return (
      <StatusPage>
        <AppName>
          <img src={akLogo} alt="Assistancekåren logga" />
        </AppName>
        <MySpinner>Laddar...</MySpinner>
      </StatusPage>
    );
  }

  return <>{children}</>;
};

export default AppInitialization;
