import { FC, useMemo } from 'react';
import React from 'react';
import SummaryRowsDesktop from './SummaryRowsDesktop';

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
} from 'recharts';
import Color from 'constants/Color';
import ColorDotTooltip from 'components/charts/ColorDotTooltip';
import LineScatter from '../../components/LineScatter';
import { toShortSweNumber } from 'utils/numbers';
import {
  GraphWrapper,
  MyCustomLegend,
  tickSize,
  yAxisWidth,
} from '../../components/components';
import { DashboardStats } from 'api';

interface Props {
  stats: DashboardStats;
}

const MonthlyJobs: FC<Props> = ({ stats }) => {
  const [monthlyStats, thisYearMonthLabel, prevYearMonthLabel] = useMemo(() => {
    const thisYearLabel = Array.from(
      new Set(stats.monthlyStats.map((ms) => ms.month.year))
    ).join('/');
    const prevYearLabel = Array.from(
      new Set(stats.monthlyStats.map((ms) => ms.month.year - 1))
    ).join('/');

    const monthlyStats = stats.monthlyStats
      .sort((a, b) => a.month.month - b.month.month)
      .map((ms) => ({
        monthDate: new Date(ms.month.year, ms.month.month - 1),
        month: new Date(0, ms.month.month - 1).toLocaleString('sv-SE', {
          month: 'long',
        }),
        bestYear: ms.totalSalesPrice.yearOfTopValue,
        'Bäst år': Math.round(ms.totalSalesPrice.topValue),
        [thisYearLabel]: Math.round(ms.totalSalesPrice.value),
        [prevYearLabel]: Math.round(ms.totalSalesPrice.previousYearValue),
        Projektion: Math.round(ms.projectedAdditionalSalesPrice),
        towings: ms.completedTowingCount.value,
        heavyTowings: ms.heavyVehiclesCount.value,
        invoicedTowings: ms.invoicedTowingCount.value,
      }));

    return [monthlyStats, thisYearLabel, prevYearLabel] as const;
  }, [stats.monthlyStats]);

  return (
    <>
      <GraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={monthlyStats}
            barGap={0}
            maxBarSize={50}
            barCategoryGap={'20%'}
            margin={{ top: 20 }}
          >
            <Tooltip
              animationDuration={200}
              content={<ColorDotTooltip unit="kr" />}
            />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />

            <Bar dataKey={prevYearMonthLabel} fill={Color.chart.b.light} />

            <defs>
              <pattern
                id="stripes-background"
                width="4"
                height="10"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45 50 50)"
              >
                <line stroke={Color.chart.a.dark} strokeWidth="4px" y2="10" />
              </pattern>
            </defs>
            <Bar
              dataKey={thisYearMonthLabel}
              fill={Color.chart.a.light}
              stackId="projection"
            />
            <Bar
              dataKey="Projektion"
              stackId="projection"
              fill="url(#stripes-background)"
            />

            <Scatter
              dataKey="Bäst år"
              shape={LineScatter}
              color="line"
              fill="line"
            />

            <XAxis tickSize={tickSize} dataKey="month" />
            <YAxis
              width={yAxisWidth}
              tickSize={tickSize}
              tickFormatter={(v) =>
                typeof v === 'number' ? toShortSweNumber(v, 1) : v
              }
            />
            <Legend content={<MyCustomLegend />} />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphWrapper>

      {monthlyStats ? (
        <SummaryRowsDesktop
          rows={[...monthlyStats]
            .sort((a, b) => b.monthDate.getTime() - a.monthDate.getTime())
            .map((mi) => ({
              title: mi.month,
              money: Number(mi[thisYearMonthLabel]),
              towings: mi.towings,
              heavyTowings: mi.heavyTowings,
              invoicedTowings: mi.invoicedTowings,
            }))}
        />
      ) : null}
    </>
  );
};

export default MonthlyJobs;
