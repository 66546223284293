import { UserClient, WebUser } from 'api';
import { useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks';

const usersAtom = atom<WebUser[]>({
  key: 'users',
  default: [],
});

export const useUsersInitialization = (isAdmin: boolean) => {
  const updateUsers = useUpdateUsersCall();

  useEffect(() => {
    updateUsers.run(isAdmin);
  }, [isAdmin]);

  return updateUsers;
};

export const useUpdateUsersCall = () => {
  const [, setUsers] = useRecoilState(usersAtom);

  return useApiCall(UserClient, async (c, isAdmin: boolean) => {
    if (!isAdmin) {
      setUsers([]);
      return;
    }

    const users = await c.getAll();
    setUsers(users);
  });
};

const useUsers = () => {
  return useRecoilValue(usersAtom);
};

export default useUsers;
