const RoleIds = {
  admin: 1,
  stationManager: 3,
  boardMember: 4,
} as const;

export const getSweRoleNameFromId = (roleId: number) => {
  switch (roleId) {
    case RoleIds.admin:
      return 'Admin';
    case RoleIds.stationManager:
      return 'Stationsägare';
    case RoleIds.boardMember:
      return 'Styrelsemedlem';
  }
};

export default RoleIds;
