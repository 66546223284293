import useAccountInfo from 'contexts/useAccountInfo';
import { LoginReturnUrlQueryParam } from 'contexts/useAccountInfo/RequireAuth';
import { useState } from 'react';
import useQueryParams from 'utils/useQueryParams';
import useUpdateInterval from 'utils/useUpdateInterval';
import {
  AuthenticationClient,
  BankSignRequest,
  DEVONLYClient,
  LoginStatus,
} from 'api';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import LabelWrap from 'components/inputs/LabelWrap';
import styled, { css } from 'styled-components';
import Size, { MediaQuery } from 'constants/Size';
import { useHistory } from 'react-router';
import { useApiCall, RequestStatus } from 'swaggerhooks';
import Spinner from 'components/Spinner';
import React from 'react';
import Color from 'constants/Color';
import bankIdImg from 'images/bankid-white.png';
import BankIdQrInstructions from './BankIdQrInstructions';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  width: 400px;
  max-width: 100vw;
  padding: 30px ${Size.padding.page}px;

  ${MediaQuery.tablet} {
    background-color: ${Color.background.mainBackground};
    box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  }
`;

const Title = styled.h1`
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-size: ${Size.font.large};
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BankIdButton = styled(Button)<{ showBankId?: boolean }>`
  position: relative;
  padding-right: 50px;

  ${({ showBankId }) =>
    showBankId &&
    css`
      &::after {
        content: '';
        width: 35px;
        height: 100%;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;

        background-image: url('${bankIdImg}');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    `}
`;

const BankIdQrImg = styled.img`
  width: 300px;
  height: 300px;
  margin: auto;
  display: block;

  border-radius: 10px;
  border: 3px solid #ddd;
`;

const StatusText = styled.div`
  text-align: center;
`;

const LoginPage: React.FC = () => {
  const [pnr, setPnr] = useState('');
  const [qrImgTimestamp, setQrImgTimestamp] = useState(Date.now());
  const { onLoggedIn } = useAccountInfo();
  const queryParams = useQueryParams();
  const history = useHistory();

  const initiateBankIDCall = useApiCall(
    AuthenticationClient,
    (client, getQr: boolean) => client.initiateBankIDLogin(getQr)
  );
  const pollBankIDCall = useApiCall(
    AuthenticationClient,
    (client, model: BankSignRequest) => client.pollBankIDLoginStatus(model)
  );
  const devOnlyLoginCall = useApiCall(DEVONLYClient, (c, pnr: string) =>
    c.signInUser(pnr)
  );

  // BankId status poller
  const {
    startIntervalImmediately: startLoginStatusPoll,
    stopInterval: stopLoginStatusPoll,
    isRunning: isPollRunning,
  } = useUpdateInterval(async (orderRef: string) => {
    const [response, error] = await pollBankIDCall.run(
      new BankSignRequest({
        orderRef,
        rememberMe: true,
      })
    );

    if (response && !error) {
      switch (response.status) {
        case LoginStatus.Complete: {
          stopLoginStatusPoll();
          stopQrImgUpdate();

          if (response.jwtToken && response.jwtRefreshToken && response.user) {
            onLoggedIn(
              response.jwtToken,
              response.jwtRefreshToken,
              response.user
            );
            const navTo = queryParams.get(LoginReturnUrlQueryParam) ?? '/';
            history.replace(navTo, { replace: true });
          }
          break;
        }

        case LoginStatus.Error: {
          stopLoginStatusPoll();
          stopQrImgUpdate();
          break;
        }
      }
    } else {
      stopLoginStatusPoll();
      stopQrImgUpdate();
    }
  }, 1000);

  const {
    startIntervalImmediately: startQrImgUpdate,
    stopInterval: stopQrImgUpdate,
  } = useUpdateInterval(() => {
    setQrImgTimestamp(Date.now());
  }, 5000);

  const getAutoStartLink = (autoStartToken: string) =>
    `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;

  const openAutoStartToken = (autoStartToken: string) => {
    window.location.replace(getAutoStartLink(autoStartToken));
  };

  const InitateLogin = async (qrLogin: boolean) => {
    pollBankIDCall.setResponse(undefined);
    const [response] = await initiateBankIDCall.run(qrLogin);

    if (response !== null) {
      if (
        response?.status === LoginStatus.InitiationSuccess &&
        response.orderRef
      ) {
        if (!qrLogin && response.autoStartToken)
          openAutoStartToken(response.autoStartToken);

        startLoginStatusPoll(response.orderRef);
        if (qrLogin) startQrImgUpdate();
      }
    }
  };

  const devOnlySignIn = async () => {
    const [response, err] = await devOnlyLoginCall.run(pnr);
    if (
      !err &&
      response?.jwtToken &&
      response.jwtRefreshToken &&
      response.user
    ) {
      onLoggedIn(response.jwtToken, response.jwtRefreshToken, response.user);
      const navTo = queryParams.get(LoginReturnUrlQueryParam) ?? '/';
      history.replace(navTo, { replace: true });
    }
  };

  const renderContent = () => {
    if (initiateBankIDCall.status === RequestStatus.Fetching) {
      return (
        <Spinner>
          {pollBankIDCall.response?.statusMessage ?? 'Ansluter till BankID...'}
        </Spinner>
      );
    }

    if (isPollRunning) {
      return (
        <>
          <Title>Logga in</Title>

          {initiateBankIDCall.response?.qrImageUrl ? (
            <BankIdQrImg
              src={
                initiateBankIDCall.response.qrImageUrl + `?t=${qrImgTimestamp}`
              }
              alt="BankID QR"
              width="300"
              height="300"
            />
          ) : (
            <Spinner />
          )}
          {pollBankIDCall.response?.statusMessage && (
            <StatusText>{pollBankIDCall.response.statusMessage}</StatusText>
          )}

          {initiateBankIDCall.response?.autoStartToken && (
            <StatusText>
              <a
                href={getAutoStartLink(
                  initiateBankIDCall.response.autoStartToken
                )}
              >
                Öppna BankID-appen på den här enheten
              </a>
            </StatusText>
          )}

          {initiateBankIDCall.response?.qrImageUrl && <BankIdQrInstructions />}
        </>
      );
    }

    const errorMessage = [
      initiateBankIDCall.status,
      pollBankIDCall.status,
    ].includes(RequestStatus.Error)
      ? 'Ett fel inträffade. Försök igen.'
      : initiateBankIDCall.response?.status === LoginStatus.Error
      ? initiateBankIDCall.response.statusMessage
      : pollBankIDCall.response?.status === LoginStatus.Error
      ? pollBankIDCall.response.statusMessage
      : '';

    const isDev = process.env.NODE_ENV === 'development';

    return (
      <>
        <Title>Logga in</Title>

        <ButtonsColumn>
          <BankIdButton
            type="button"
            onClick={() => InitateLogin(true)}
            showBankId
          >
            BankID på annan enhet
          </BankIdButton>

          <BankIdButton
            type="button"
            onClick={() => InitateLogin(false)}
            showBankId
          >
            Öppna BankID
          </BankIdButton>

          {isDev && (
            <LabelWrap label="Personnummer (Dev sign in)">
              <Input
                type="text"
                name="socialsecuritynumber"
                onChange={(e) =>
                  setPnr(e.currentTarget.value.replaceAll(/\D/g, ''))
                }
                value={pnr}
              />
            </LabelWrap>
          )}
          {isDev && (
            <BankIdButton onClick={devOnlySignIn}>Dev sign in</BankIdButton>
          )}
        </ButtonsColumn>

        <div>{errorMessage}</div>
      </>
    );
  };

  return (
    <Wrapper>
      <Card>{renderContent()}</Card>
    </Wrapper>
  );
};

export default LoginPage;
