import React, { FC, useState } from 'react';
import { DashboardStats } from 'api';
import {
  BulletInfoText,
  DashboardGridItem,
  screenInsetVar,
} from '../../components/components';
import WeeklyJobs from './WeeklyJobs';
import YearlyJobs from './YearlyJobs';
import MonthlyJobs from './MonthlyJobs';
import styled from 'styled-components';
import ButtonStrip from '../../components/ButtonStrip';
import DashboardGridItemHeader from '../../components/DashboardGridItemHeader';

const MyButtonStrip = styled(ButtonStrip)`
  margin: 0 ${screenInsetVar};
  margin-top: 20px;
`;

// Toggle visibility using css to keep all of the graphs mounted.
const VisibilityWrapper = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'contents' : 'none')};
`;

enum SelectedView {
  Week = 0,
  Month = 1,
  Year = 2,
}

interface Props {
  stats: DashboardStats;
  todaysTowingCount: number;
}

const JobsGridItem: FC<Props> = ({ stats, todaysTowingCount }) => {
  const [selectedView, setSelectedView] = useState<SelectedView>(
    SelectedView.Week
  );

  return (
    <DashboardGridItem>
      <DashboardGridItemHeader
        title="Uppdrag"
        infoText={<BulletInfoText bulletText={stats.statsInfoText} />}
      />
      <MyButtonStrip
        buttonLabels={['Vecka', 'Månad', 'År']}
        selectedButton={selectedView}
        onClick={setSelectedView}
      />

      <VisibilityWrapper visible={selectedView === SelectedView.Week}>
        <WeeklyJobs stats={stats} todaysTowingCount={todaysTowingCount} />
      </VisibilityWrapper>

      <VisibilityWrapper visible={selectedView === SelectedView.Month}>
        <MonthlyJobs stats={stats} />
      </VisibilityWrapper>

      <VisibilityWrapper visible={selectedView === SelectedView.Year}>
        <YearlyJobs stats={stats} />
      </VisibilityWrapper>
    </DashboardGridItem>
  );
};

export default JobsGridItem;
