import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import Button from './inputs/Button';

const ErrorScreen = styled.div<{ hasChildren?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  svg {
    font-size: 30px;
    margin-bottom: 10px;
  }

  button:last-child {
    margin-top: 10px;
  }
`;

interface Props {
  onRetryClick(): void;
  className?: string;
}

const ErrorTryAgain: FC<Props> = ({ onRetryClick, className }) => (
  <ErrorScreen className={className}>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <b>Ett fel har inträffat</b>
    <Button onClick={onRetryClick}>Försök igen</Button>
  </ErrorScreen>
);

export default ErrorTryAgain;
