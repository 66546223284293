import Color from 'constants/Color';
import React from 'react';

interface Props {
  cx: number;
  cy: number;
  xAxis: { bandSize: number };
  bestYear: number;
}

const LineScatter = ({ cx, cy, xAxis, bestYear }: Props) => {
  const lineRadius = Math.min(60, xAxis.bandSize / 2 - 10);

  return (
    <g>
      <line
        x1={cx - lineRadius}
        x2={cx + lineRadius}
        y1={cy}
        y2={cy}
        strokeWidth={1}
        stroke={Color.border.dark}
      />
      <text x={cx} y={cy - 2} textAnchor="middle" fill={Color.border.dark}>
        {bestYear}
      </text>
    </g>
  );
};

export default LineScatter;
