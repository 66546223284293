import styled, { css } from 'styled-components';
import Color from 'constants/Color';
import Size from 'constants/Size';

export const defaultInputStyle = css`
  padding: 0.5em;

  border: 1px solid transparent;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: ${Size.radius.small}px;
  background: ${Color.background.mainBackground};
  color: ${Color.foreground.mainBackground};
  font: inherit;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border: 1px solid ${Color.background.focus};
  }
`;

const Input = styled.input`
  ${defaultInputStyle}
`;

export default Input;
