import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdType } from 'api';
import Select from 'components/inputs/Select';
import Color from 'constants/Color';
import RoleIds from 'constants/RoleIds';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import useGetFullStationName from 'contexts/useGetFullStationName';
import React from 'react';
import { FC, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { makeStringComparator } from 'utils/sorting';

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 15px;
`;

const MySelect = styled(Select)`
  position: relative;
  z-index: 2;
  padding-left: 35px;
  width: 100%;

  border: 1px solid ${Color.border.main};
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  font: inherit;
  color: inherit;

  &:disabled {
    border: 0;
  }
` as typeof Select;

const DisabledCompanyName = styled.div`
  padding-left: 35px;
  padding-right: 10px;
`;

interface Props {
  disabled?: boolean;
  className?: string;
  onStationSelected?(): void;
}

const StationSelector: FC<Props> = ({
  onStationSelected,
  disabled,
  className,
}) => {
  const { accountInfo, onSelectStation } = useAccountInfo();
  const { activeStationIDs } = useBasicData();
  const getFullStationName = useGetFullStationName();

  const selectableStations: {
    id: number | null;
    name: string;
  }[] = useMemo(() => {
    if (accountInfo.user?.stationIds) {
      const isAdmin = accountInfo.user.roles?.includes(RoleIds.admin);
      const isBoardMember = accountInfo.user.roles?.includes(
        RoleIds.boardMember
      );
      const activeStationIDsSet = new Set(activeStationIDs);

      const stationOptions = (isAdmin
        ? activeStationIDs.map((stationId) => ({
            id: stationId,
            name: getFullStationName(stationId),
          }))
        : accountInfo.user.stationIds
            .filter((stationId) => activeStationIDsSet.has(stationId))
            .map((stationId) => ({
              id: stationId,
              name: getFullStationName(stationId),
            }))
      ).sort(makeStringComparator((item) => item.name));

      if (isBoardMember || isAdmin) {
        return [{ id: null, name: 'Alla stationer' }, ...stationOptions];
      }
      return stationOptions;
    }
    return [];
  }, [
    accountInfo.user?.roles,
    accountInfo.user?.stationIds,
    getFullStationName,
    activeStationIDs,
  ]);

  useEffect(() => {
    // Reset selected station to first option if the selected option isn't in the list
    if (
      selectableStations.length > 0 &&
      (accountInfo.selectedStationId === null ||
        !selectableStations.find(
          (company) => company.id === accountInfo.selectedStationId
        ))
    ) {
      onSelectStation(selectableStations[0].id);
      return;
    }

    // Set selected akCompany to null if there's no selectable akCompanies
    if (
      accountInfo.selectedStationId !== null &&
      selectableStations.length === 0
    ) {
      onSelectStation(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.selectedStationId, accountInfo.user, selectableStations]);

  if (!accountInfo.user) {
    return null; // user is not logged in, or app is still loading
  }

  return (
    <Wrapper className={className}>
      <Icon icon={faWarehouse} />
      {disabled ? (
        <DisabledCompanyName>
          {
            selectableStations.find(
              (company) => company.id === accountInfo.selectedStationId
            )?.name
          }
        </DisabledCompanyName>
      ) : (
        <MySelect
          disabled={disabled}
          onChange={(stationId) => {
            onSelectStation(stationId);
            onStationSelected?.();
          }}
          value={accountInfo.selectedStationId}
          options={selectableStations.map((company) => ({
            label: company.name,
            value: company.id,
          }))}
        />
      )}
    </Wrapper>
  );
};

export default StationSelector;
