import React, { Component, lazy, Suspense, ReactChild, FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Color from 'constants/Color';
import Header from 'containers/Header';
import { Route, Redirect, Switch } from 'react-router-dom';
import Routes from 'constants/Route';
import Spinner from 'components/Spinner';
import { RecoilRoot } from 'recoil';
import AppInitialization from 'AppInitialization';
import LoginPage from 'containers/pages/Account/Login';
import ApiCallConfiguration from 'contexts/ApiCallConfiguration';
import RequireAuth from 'contexts/useAccountInfo/RequireAuth';
import useAccountInfo from 'contexts/useAccountInfo';
import RoleIds from 'constants/RoleIds';
import DashboardStatisticsPage from 'containers/pages/StatsDashboard/DashboardStatisticsPage';
import { RenderModalStack } from 'contexts/useModalStack';

// Lazy load these pages, since they won't be used by dashboard-only users.
const AssistHeroPage = lazy(
  () => import('containers/pages/StatsDashboard/AssistHeroPage')
);
const StatisticsPage = lazy(
  () => import('containers/pages/AkManager/StatisticsPage')
);
const QuarterlyReportsPage = lazy(
  () => import('containers/pages/AkManager/QuarterlyReportsPage')
);
const BillingPage = lazy(
  () => import('containers/pages/AkManager/BillingPage')
);
const AnnualReportsPage = lazy(
  () => import('containers/pages/AkManager/AnnualReportsPage')
);
const StationCoveragePage = lazy(
  () => import('containers/pages/AkManager/StationCoveragePage')
);
const AdminPage = lazy(() => import('containers/pages/AkManager/AdminPage'));

const GlobalStyles = createGlobalStyle`
html, body {
  height: 100%;
  margin: 0;

  background: ${Color.background.mainBackground};
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}


* {
  box-sizing: border-box;
}

`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const fallbackRoute = Routes.StatsDashboard.Statistics;
export const fallbackRoutePermissions = [
  RoleIds.stationManager,
  RoleIds.boardMember,
  RoleIds.admin,
];

const RootReroute: FC = ({ children }) => {
  const {
    isLoggedIn,
    accountInfo: { user },
  } = useAccountInfo();

  if (!isLoggedIn) {
    return <Redirect to={Routes.Account.LogIn} />;
  }

  if (user?.roles?.includes(RoleIds.admin)) {
    return <Redirect to={Routes.AkManager.Statistics} />;
  }

  return <Redirect to={fallbackRoute} />;
};

export default class App extends Component {
  static displayName = App.name;

  render() {
    const mkAuthRoute = (
      path: string,
      element: ReactChild,
      allowedRoleIds?: number[]
    ) => {
      return (
        <Route
          render={() => (
            <RequireAuth allowedRoleIds={allowedRoleIds}>{element}</RequireAuth>
          )}
          path={path}
        />
      );
    };

    return (
      <RecoilRoot>
        <ApiCallConfiguration>
          <Layout>
            <GlobalStyles />

            <AppInitialization>
              <Header />

              <Suspense fallback={<Spinner />}>
                <Switch>
                  <Route exact path="/" render={() => <RootReroute />} />

                  <Route path={Routes.Account.LogIn} component={LoginPage} />

                  {/* This route is a fallback route if the user tries to access a route he/she doesn't have access to */}
                  {mkAuthRoute(
                    fallbackRoute,
                    <DashboardStatisticsPage />,
                    fallbackRoutePermissions
                  )}
                  {mkAuthRoute(
                    Routes.StatsDashboard.AkHero,
                    <AssistHeroPage />,
                    [RoleIds.stationManager, RoleIds.boardMember, RoleIds.admin]
                  )}

                  {mkAuthRoute(
                    Routes.AkManager.Statistics,
                    <StatisticsPage />,
                    [RoleIds.admin]
                  )}
                  {mkAuthRoute(Routes.AkManager.Billing, <BillingPage />, [
                    RoleIds.admin,
                  ])}
                  {mkAuthRoute(
                    Routes.AkManager.QuarterlyReports,
                    <QuarterlyReportsPage />,
                    [RoleIds.admin]
                  )}
                  {mkAuthRoute(
                    Routes.AkManager.AnnualReports,
                    <AnnualReportsPage />,
                    [RoleIds.admin]
                  )}
                  {mkAuthRoute(
                    Routes.AkManager.StationCoverage,
                    <StationCoveragePage />,
                    [RoleIds.admin]
                  )}
                  {mkAuthRoute(Routes.AkManager.Admin, <AdminPage />, [
                    RoleIds.admin,
                  ])}

                  <Route
                    path="*"
                    render={() => <Redirect to={fallbackRoute} />}
                  />
                </Switch>
              </Suspense>
            </AppInitialization>

            <RenderModalStack />
          </Layout>
        </ApiCallConfiguration>
      </RecoilRoot>
    );
  }
}
