import React, { FC, useState } from 'react';
import {
  ColumnHead,
  RowBackground,
  RowTitle,
  RowsWrapper,
  ValueCell,
} from '../JobsGridItem/SummaryRowsDesktop';
import { IDashboardInboundStatsRow } from 'api';
import styled from 'styled-components';
import Color from 'constants/Color';
import { TextButton, screenInsetVar } from '../../components/components';

const ColorDot = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  border: 1px solid ${Color.border.main};
  margin-right: 10px;
`;

const ShowAllRowsButton = styled(TextButton)`
  margin-left: ${screenInsetVar};
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: auto;
`;

interface Props {
  rows: (IDashboardInboundStatsRow & { color: string })[];
}

const InboundList: FC<Props> = ({ rows }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <RowsWrapper
        style={{
          gridTemplateColumns:
            '1fr minmax(max-content, auto) minmax(max-content, auto)',
        }}
      >
        <ColumnHead style={{ gridColumn: '2/3' }}>Antal</ColumnHead>
        <ColumnHead style={{ gridColumn: '3/4' }}>Summa</ColumnHead>

        {(showAll ? rows : rows.slice(0, 6)).map(
          ({ inboundName, revenue, assignmentCount, color }, i) => {
            const gridRowStart = i * 2 + 2;
            const gridRowEnd = gridRowStart + 2;

            return (
              <React.Fragment key={inboundName}>
                {i % 2 === 0 && (
                  <RowBackground
                    style={{
                      gridRowStart: gridRowStart,
                      gridRowEnd,
                    }}
                  />
                )}

                <RowTitle style={{ gridRow: `${gridRowStart}/${gridRowEnd}` }}>
                  <ColorDot style={{ backgroundColor: color }} />

                  {inboundName}
                </RowTitle>
                <ValueCell
                  style={{
                    gridRowStart,
                    gridRowEnd,
                    gridColumn: '2/3',
                  }}
                >
                  {assignmentCount.toLocaleString('sv-SE')}
                </ValueCell>
                <ValueCell
                  style={{
                    gridRowStart,
                    gridRowEnd,
                    gridColumn: '3/4',
                  }}
                >
                  {Math.round(revenue).toLocaleString('sv-SE')} kr
                </ValueCell>
              </React.Fragment>
            );
          }
        )}
      </RowsWrapper>

      <ShowAllRowsButton onClick={() => setShowAll(!showAll)}>
        {showAll ? '<< Visa färre ingångar' : 'Visa alla ingångar >>'}
      </ShowAllRowsButton>
    </>
  );
};

export default InboundList;
