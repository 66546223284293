import Modal from 'components/Modal';
import { ReactChild, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { ApiCallGlobalConfig, ApiCallGlobalConfigContext } from 'swaggerhooks';

import {
  ApiException,
  AuthenticationClient,
  RefreshJwtTokenResponse,
} from 'api';
import ApiErrorModalIds from './ApiErrorModalIds';
import useAccountInfo from 'contexts/useAccountInfo';
import { pollersErrorModalId } from 'constants/AppConstants';
import useModalStack from 'contexts/useModalStack';
import React from 'react';

const Pre = styled.pre`
  max-width: 90vw;
  max-height: 80vh;
  overflow: auto;
`;

const ApiCallConfiguration: React.FC = ({ children }) => {
  const { push: pushModal, pop: popModal } = useModalStack();
  const { accountInfo, onLogOut, onTokenRefreshed } = useAccountInfo();

  const accountInfoRef = useRef(accountInfo);
  accountInfoRef.current = accountInfo;

  const validateRefreshTokenCaller = useRef<Promise<RefreshJwtTokenResponse | null> | null>(
    null
  );
  const isValidatingRefreshToken = useRef(false);

  const authorizationHeaderFetch = useCallback(
    (
      [...args]: [RequestInfo | URL, (RequestInit | undefined)?],
      token: string
    ): ReturnType<typeof fetch> =>
      fetch(args[0], {
        ...(args[1] ?? {}),
        headers: {
          ...(args[1]?.headers ?? {}),
          Authorization: `Bearer ${token}`,
        },
      }),
    []
  );

  const tokenAutorefreshFetch: typeof fetch = useCallback(async (...args) => {
    const { token, refreshToken } = accountInfoRef.current;

    if (!token) {
      return fetch(...args);
    }

    let upToDateToken = token;
    // If a new token is being fetched, wait for it instead of trying to fetch with the current, expired token.
    if (isValidatingRefreshToken.current) {
      upToDateToken =
        (await validateRefreshTokenCaller.current)?.jwtToken ?? token;
    }
    const response = await authorizationHeaderFetch(args, upToDateToken);

    // If we got 401 unauthorized response, try to get a new token and then redo the api call.
    if (response.status === 401) {
      if (!refreshToken) {
        onLogOut();
        return response;
      }

      // There should only be one validateRefreshToken api call running at a time. If there's none currently running, start one.
      // If 'token' does not equal 'accountInfoRef.current.token', we won't fetch a new token,
      // because a new one was fetched in the background while one of the 'await':s above was running.
      // await:ing 'validateRefreshTokenCaller.current' will return the latest fetched token.
      if (
        !isValidatingRefreshToken.current &&
        token === accountInfoRef.current.token
      ) {
        isValidatingRefreshToken.current = true;

        validateRefreshTokenCaller.current = (async () => {
          const authClient = new AuthenticationClient(undefined, {
            fetch: (...args) => authorizationHeaderFetch(args, token),
          });

          let refreshTokenResponse: RefreshJwtTokenResponse | null = null;
          try {
            refreshTokenResponse = await authClient.refreshJwtToken(
              token,
              refreshToken
            );

            if (
              refreshTokenResponse.jwtRefreshToken &&
              refreshTokenResponse.jwtToken
            ) {
              onTokenRefreshed(
                refreshTokenResponse.jwtToken,
                refreshTokenResponse.jwtRefreshToken
              );
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('Error while trying to refresh login token', err);
            onLogOut();
          }

          return refreshTokenResponse;
        })();
      }

      // validatingRefreshTokenPromise.current should never be null here, but TS likes this "if" :P
      if (validateRefreshTokenCaller.current) {
        const refreshTokenResponse = await validateRefreshTokenCaller.current;
        isValidatingRefreshToken.current = false;

        if (
          refreshTokenResponse?.jwtToken &&
          refreshTokenResponse?.jwtRefreshToken
        ) {
          // do the api call again, but now with updated token
          return authorizationHeaderFetch(args, refreshTokenResponse.jwtToken);
        }
      }
    }
    return response;
  }, []);

  const apiCallConfig = useMemo(
    (): ApiCallGlobalConfig => ({
      onApiError: (err, apiCallOptions) => {
        const errorModal = (
          modalTypeId: string,
          title: string,
          body: ReactChild
        ) =>
          pushModal(
            <Modal
              buttons={[
                {
                  label: 'Ok',
                  onClick: () => {
                    popModal(modalTypeId);
                    popModal(apiCallOptions.errorModalId);
                    popModal(pollersErrorModalId);
                  },
                },
              ]}
              title={title}
            >
              {body}
            </Modal>,
            modalTypeId
          );

        if (err instanceof ApiException) {
          console.log(
            'API error',
            err,
            err.message,
            err.response,
            err.headers,
            err.name,
            err.result,
            err.status,
            err.stack
          );

          if (err.status === 403) {
            errorModal(
              ApiErrorModalIds.AccessDenied,
              'Fel',
              'Du har tyvärr inte tillgång till den här funktionen.'
            );
          } else if (err.status === 401) {
            errorModal(
              ApiErrorModalIds.LoggedOut,
              'Utloggad',
              'Du har blivit utloggad. Logga in och försök igen.'
            );
          } else {
            errorModal(
              ApiErrorModalIds.ServerError,
              'Fel',
              <>
                Ett serverfel har inträffat :&apos;(
                <br />
                <br />
                {process.env.NODE_ENV === 'development' && (
                  <Pre>{err.response}</Pre>
                )}
              </>
            );
          }
        } else {
          console.log('API error', err);

          if (err instanceof TypeError) {
            errorModal(
              ApiErrorModalIds.ConnectionError,
              'Anslutningsfel',
              'Kunde inte ansluta till servern. Kontrollera din internetanslutning.'
            );
          }
        }
      },
      constructClient: (clientConstructor) =>
        new clientConstructor(undefined, {
          fetch: accountInfoRef.current.token
            ? tokenAutorefreshFetch
            : (...args: Parameters<typeof fetch>) => fetch(...args),
        }),
    }),
    [popModal, pushModal, tokenAutorefreshFetch]
  );

  return (
    <ApiCallGlobalConfigContext.Provider value={apiCallConfig}>
      {children}
    </ApiCallGlobalConfigContext.Provider>
  );
};

export default ApiCallConfiguration;
