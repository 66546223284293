import React, { FC } from 'react';
import {
  DashboardGridItem,
  TextButton,
  screenInsetVar,
} from '../components/components';
import DashboardGridItemHeader from '../components/DashboardGridItemHeader';
import akHeroLogoImg from 'images/AssistHeroLogo.svg';
import styled from 'styled-components';
import { useResponse } from 'swaggerhooks';
import { AkHeroClient } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import ApiStatus from 'api/ApiStatus';
import useGetFullStationName from 'contexts/useGetFullStationName';
import {
  ColumnHead,
  RowBackground,
  RowTitle,
  RowsWrapper,
  ValueCell,
} from './JobsGridItem/SummaryRowsDesktop';
import Color from 'constants/Color';
import { useHistory } from 'react-router';
import Route from 'constants/Route';

const MyDashboardGridItem = styled(DashboardGridItem)`
  gap: 20px;
`;

const originalImgHeight = 56.55;
const originalImgWidth = 300;

const logoHeight = 30;
const AkHeroLogo = styled.div`
  display: inline-block;
  height: ${logoHeight}px;
  width: ${(originalImgWidth / originalImgHeight) * logoHeight}px;
  margin-right: 10px;

  background-image: url(${akHeroLogoImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StationName = styled.div`
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Num = styled.div`
  width: 25px;
  margin-right: 5px;
  font-weight: 400;
  text-align: right;
`;

const ScoreCell = styled(ValueCell)`
  color: ${Color.background.accent};
  font-weight: 600;
`;

const MyLinkButton = styled(TextButton)`
  margin-left: ${screenInsetVar};
  margin-right: auto;
`;

interface Props {}

const AKHeroGridItem: FC<Props> = () => {
  const history = useHistory();
  const {
    accountInfo: { selectedStationId },
  } = useAccountInfo();

  const getFullStationName = useGetFullStationName();
  const toplistsResponse = useResponse(
    AkHeroClient,
    (c) => c.getTopLists(selectedStationId),
    [selectedStationId]
  );

  const stationName =
    selectedStationId === null ? null : getFullStationName(selectedStationId);

  return (
    <MyDashboardGridItem>
      <DashboardGridItemHeader
        title={
          <>
            <AkHeroLogo />
            <StationName>{stationName ?? 'AK'} Allstars</StationName>
          </>
        }
      />

      <ApiStatus
        watch={toplistsResponse}
        render={(response) => {
          return (
            <RowsWrapper
              style={{
                gridTemplateColumns: '1fr minmax(max-content, auto)',
              }}
            >
              <ColumnHead
                style={{
                  gridColumn: '2/3',
                }}
              >
                Poäng
              </ColumnHead>

              {(selectedStationId === null
                ? response.driverRankings_AllOfAk
                : response.driverRankings_ForStationID
              ).map((ranking, i) => {
                const gridRowStart = i * 2 + 2;
                const gridRowEnd = gridRowStart + 2;

                return (
                  <>
                    {i % 2 === 0 && (
                      <RowBackground
                        style={{
                          gridRowStart: gridRowStart,
                          gridRowEnd,
                        }}
                      />
                    )}

                    <RowTitle
                      style={{
                        gridRowStart,
                        gridRowEnd,
                        gridColumn: '1/2',
                      }}
                    >
                      <Num>{i + 1}.</Num>
                      {ranking.name}
                    </RowTitle>

                    <ScoreCell
                      style={{
                        gridRowStart,
                        gridRowEnd,
                        gridColumn: '2/3',
                      }}
                    >
                      {Math.floor(ranking.totalScore)}
                    </ScoreCell>
                  </>
                );
              })}
            </RowsWrapper>
          );
        }}
      />

      <MyLinkButton
        onClick={() => {
          history.push(Route.StatsDashboard.AkHero);
        }}
      >
        {'Se topplistor >>'}
      </MyLinkButton>
    </MyDashboardGridItem>
  );
};

export default AKHeroGridItem;
