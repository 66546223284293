import { faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'constants/Color';
import Size, { MediaQuery } from 'constants/Size';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Button = styled.button`
  margin: 0;
  padding: 0;

  border: 0;
  background: transparent;
  font: inherit;
  font-size: 18px;
  color: ${Color.border.dark};
  cursor: pointer;
`;

interface Insets {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

const slideInAnimation = keyframes`
  from {
    transform: translate(0%, -20px);
    opacity: 0;
  }

  to {
    transform: translate(0%, 0);
    opacity: 1;
  }
`;

const Overlay = styled.div<{ insets: Insets }>`
  position: fixed;
  z-index: 2;
  top: 50vh;
  left: 10vw;
  right: 10vw;
  transform: translate(0%, -50%);
  margin: auto 0;
  display: inline-block;
  padding: 20px;

  font-size: ${Size.font.small};
  color: ${Color.foreground.mainBackground};
  text-align: left;
  background-color: ${Color.background.mainBackground};
  box-shadow: ${Color.boxShadow.main.down},
    0 0 0 100vw ${Color.background.darkOverlay};
  cursor: default;
  animation: ${slideInAnimation} 0.2s;

  ${MediaQuery.tablet} {
    inset: unset;
    ${({ insets }) =>
      Object.entries(insets)
        .filter(([, value]) => value !== undefined)
        .map(([pos, value]) => `${pos}: ${value}px;`)}
    transform: none;
    max-width: 500px;

    box-shadow: ${Color.boxShadow.main.down};
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  font-size: ${Size.font.medium};
  font-weight: bold;
`;

const CloseButton = styled.button`
  margin: -5px;
  margin-left: auto;
  padding: 5px;

  border: none;
  background: transparent;
  color: ${Color.foreground.mainBackground};
  font-size: 25px;
  cursor: pointer;

  ${MediaQuery.tablet} {
    font-size: 20px;
  }
`;

interface Props {
  title?: string;
}

const InfoButton: FC<Props> = ({ title, children }) => {
  const [open, setOpen] = useState<false | Insets>(false);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickHandler = (eve: MouseEvent) => {
      if (
        overlayRef.current &&
        (eve.target === overlayRef.current ||
          (eve.target instanceof Node &&
            overlayRef.current.contains(eve.target)))
      ) {
        return;
      }

      setOpen(false);
    };

    if (open) {
      window.addEventListener('click', clickHandler);
    }

    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [open]);

  const handleOpenClick = (eve: React.MouseEvent) => {
    if (!(eve.target instanceof Element)) return;

    const bodyRect = document.body.getBoundingClientRect();
    const clickRect = eve.target.getBoundingClientRect();

    const xPercent = clickRect.left / bodyRect.width;
    const yPercent = clickRect.top / bodyRect.height;

    if (xPercent > 0.5) {
      if (yPercent > 0.5) {
        setOpen({
          right: bodyRect.width - clickRect.left,
          bottom: bodyRect.height - clickRect.top,
        });
      } else {
        setOpen({
          right: bodyRect.width - clickRect.left,
          top: clickRect.top + clickRect.height,
        });
      }
    } else {
      if (yPercent > 0.5) {
        setOpen({
          left: clickRect.left + clickRect.width,
          bottom: bodyRect.height - clickRect.top,
        });
      } else {
        setOpen({
          left: clickRect.left + clickRect.width,
          top: clickRect.top + clickRect.height,
        });
      }
    }
  };

  return (
    <Wrapper>
      <Button onClick={handleOpenClick}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </Button>
      {open && (
        <Overlay ref={overlayRef} insets={open}>
          <Title>
            {title}
            <CloseButton onClick={() => setOpen(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </CloseButton>
          </Title>

          {children}
        </Overlay>
      )}
    </Wrapper>
  );
};

export default InfoButton;
